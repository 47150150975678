import request from './request'



export function getQrCode(query) {
    return request({
        url: '/portal/download/qrcode',
        method: 'post',
        data: query
    });
}

export function postSmsCode(query){
    return request({
        url: '/portal/push/sms',
        method: 'post',
        data: query
    });
}

export function postLogin(query){
    return request({
        url: '/portal/user/login',
        method: 'post',
        data: query
    });
}

export function postMyInfo(query){
    return request({
        url: '/portal/user/info',
        method: 'post',
        data: query
    });
}

export function postMyAppointments(query){
    return request({
        url: '/portal/user/appointment/list',
        method: 'post',
        data: query
    });
}
export function postSaveInfo(query){
    return request({
        url: '/portal/user/improveinfo',
        method: 'post',
        data: query
    });
}

export function getsdk(query){
    return request({
        url: '/portal/download/sdk',
        method: 'get',
        data: query
    });
}

export function postSdkMenu(query){
    return request({
        url: '/portal/word/menu',
        method: 'post',
        data: query
    });
}

export function postSdkContent(query){
    return request({
        url: '/portal/word/content',
        method: 'post',
        data: query
    });
}

export function getskuList(queryId){
    return request({
        url: '/portal/product/sku/list/'+queryId,
        method: 'get',
        data: null
    });
}

export function postAppointmentAdd(query){
    return request({
        url: '/portal/user/appointment/add',
        method: 'post',
        data: query
    });
}

export function ConsultAdd(query){
    return request({
        url: '/portal/consult/add',
        method: 'post',
        data: query
    });
}

export function cancelAppointment(queryId){
    return request({
        url: '/portal/user/appointment/cancel/'+queryId,
        method: 'get',
        data: null
    });
}

export function appSetting(queryId) {
    return request({
        url: '/portal/user/app/setting/'+queryId,
        method: 'get'
    });
}

export function appAdd(query) {
    return request({
        url: '/portal/user/app/add',
        method: 'post',
        data: query
    });
}

export function appModify(query) {
    return request({
        url: '/portal/user/app/modify',
        method: 'post',
        data: query
    });
}

export function appList(query) {
    return request({
        url: '/portal/user/app/list',
        method: 'post',
        data: query
    });
}

export function appFirst(query) {
    return request({
        url: '/portal/user/app/first',
        method: 'post',
        data: query
    });
}

export function appDelete(query) {
    return request({
        url: '/portal/user/app/delete',
        method: 'post',
        data: query
    });
}

export function appPut(query) {
    return request({
        url: '/portal/user/app/put',
        method: 'post',
        data: query
    });
}

export function Videolist(query) {
    return request({
        url: '/portal/user/video/list',
        method: 'post',
        data: query
    });
}

export function addVideo(query) {
    return request({
        url: '/portal/user/video/add',
        method: 'post',
        data: query
    });
}

export function delVideo(query) {
    return request({
        url: '/portal/user/video/delete',
        method: 'post',
        data: query
    });
}

