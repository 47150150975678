<template>
  <div id="app">
    <nav>
      <div class="logo">
        <router-link to="/"><img src="./assets/images/logo.png"></router-link>
      </div>
      <div class="menu">
        <router-link to="/Through">Product introduction</router-link>
        <!-- <router-link to="/UserGuide">Usage Tutorial</router-link> -->
        <router-link to="/CompanyProfile">Company overview</router-link>
        <router-link to="/ServicesFeatures">Services and features</router-link>
        <router-link to="/CreatorCommunity">Creators community</router-link>
      </div>
      <div class="download">
        <button class="top-btn" @click="to_download">Download</button>
      </div>
    </nav>
    <router-view />
    <footer>
      <div class="footer-floor1">
        <div class="footer-floor1-panel1">
          <router-link to="/Appointment?productId=b384edd2-7707-11ee-b86f-00163e2ab162" class="trans-btn">Book
            now</router-link>
          <router-link to="/EnquireNow" class="trans-btn">Inquire now</router-link>
        </div>
        <div class="footer-floor1-panel2">
          <router-link to="/CompanyProfile" class="trans-btn">About us</router-link>
          <!-- <router-link to="/Meta" class="trans-btn">Future metaverse</router-link> -->
          <router-link to="/Ar" class="trans-btn">Future AR</router-link>
          <router-link to="/Sdk" class="trans-btn">Future Developer Alliance</router-link>
        </div>
      </div>
      <!-- <div class="footer-floor2">
      <router-link to="/" class="trans-btn">United States</router-link>
      <i style="color: #C5C5C5;">|</i>
      <a class="trans-btn" style="color: #C5C5C5;" href="https://www.future.top">中国</a>
     </div> -->
      <div class="footer-floor3">
        <!-- <p>100 CATALONIA IRVINE, CA 92618</p>
      <p>
        <span>Copyright  2020-2021 Core Intelligence Technology Co., Ltd.</span>
        <span>鄂ICP备2021019257号-1   鄂ICP备2021019257号</span>
      </p> -->
      </div>
    </footer>
  </div>
</template>

<style lang="less">
@font-face {
  font-family: "hyyakuheiw";
  src: url('./font-familys/hyyakuheiw.ttf')
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  position: relative;
  z-index: -10;
  height: auto;
  margin: auto;
}

body {
  position: relative;
  z-index: -100;
  scrollbar-width: none;
}

body::-webkit-scrollbar {
  width: 0;
}

nav {
  opacity: 0.8;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  background-color: black;
  width: 100%;
  height: 65px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .logo {
    width: 8%;

    img {
      width: auto;
      height: 40px;
    }
  }

  .menu {
    width: 82%;
    display: flex;
    justify-content: left;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 50px;

    a {
      color: #878787;
      cursor: pointer;
      transition: 0.5s;
      text-decoration: none;

      &.router-link-exact-active {
        color: white;
      }
    }

    a:hover {
      color: white;
    }

  }

  .download {
    width: 10%;

    .top-btn {
      color: #B5B5B5;
      background-color: transparent;
      border: 1px solid white;
      border-radius: 50px;
      padding: 3px 15px;
    }

    .top-btn:hover {
      color: white;
    }
  }
}

.trans-btn {
  text-decoration: none;
  color: white;
  display: block;
}

footer {
  width: 100%;
  height: auto;
  background-color: black;

  .footer-floor1 {
    // border: 1px solid red;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: auto;
    padding: 3rem 8rem 2rem 8rem;

    .footer-floor1-panel1 {
      // border: 1px solid gold;

      .trans-btn {
        border: 1px solid white;
        border-radius: 50px;
        margin-bottom: 1.5rem;
        padding: 5px 20px;
      }

      .trans-btn:hover {
        color: black;
        background-color: white;
      }
    }

    .footer-floor1-panel2 {
      // border: 1px solid green;
      text-align: left;
      width: 15rem;

      .trans-btn {
        color: #C5C5C5;
        height: 3rem;
        line-height: 3rem;

        &.router-link-exact-active {
          color: white;
          font-weight: bold;
        }
      }

      .trans-btn:hover {
        color: white;
        font-weight: bold;
      }
    }
  }

  .footer-floor2 {
    // border: 1px solid gold;
    display: flex;
    justify-content: left;
    align-items: center;
    column-gap: 1rem;
    padding: 0 0 1rem 8rem;
  }

  .footer-floor3 {
    // border: 1px solid green;
    color: #C5C5C5;
    font-size: 0.8rem;
    padding: 1rem 0 3rem 0;
    border-top: 1px solid #414345;
  }
}

.trunk {
  position: relative;
  z-index: -5;
  width: 100%;
  min-height: 100vh;
}

.floor {
  width: 100%;
  height: 100vh;
  position: relative;
  z-index: -1;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;

  .font-absolute {
    position: absolute;
    z-index: 1;
    // border: 1px solid red;
    width: 100%;
  }
}

.c-btn {
  border: none;
  border-radius: 50px;
  padding: 5px 30px;
}


.bottom-shadow {
  background-image: url('./assets/images/video-buttom.png');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 0.9;
}

/* 包裹箭头效果的盒子 */
.arrowSS {
  animation: bounce-inSS 2s infinite;
  position: relative;
  /* 启动动画特效 */
}

// /* 箭头效果的盒子 */
// .arrowSS_style {
//   cursor: pointer;
// }
/* 箭头动画 */
@keyframes bounce-inSS {

  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-3vh);
  }

  60% {
    transform: translateY(-1vh);
  }
}


.modal-back {
  background-color: rgba(100, 100, 100, 0.5);
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}
</style>
<script>
import { getQrCode } from './utils/api';
export default {
  data() {
    return {
      qrCodeImg: '',
      qrCodeUrl: ''
    }
  },
  created() {
    this.windowsChange();
  },
  mounted() {
    window.onresize = () => {
      this.windowsChange();
    }
  },
  methods: {
    to_download: function () {
      let _self = this;
      getQrCode(null).then(
        response => {
          if (response.code == 200) {
            let data = response.data;
            _self.qrCodeImg = data.imgbase;
            _self.qrCodeUrl = data.number;
            window.location.href = _self.qrCodeUrl;
          }
        }
      ).catch(error => {
        console.log('error:' + error);
      });
    },
    windowsChange: function () {
      var cw = document.body.clientWidth;
      if (cw < 850) {
        window.location.href = 'https://m.metamig.top/'
      }
    }
  }
}
</script>
