<template>
    <div class="trunk v-home">
        <div class="floor">
            <video muted="muted" autoplay="autoplay" loop="loop">
                <source src="../assets/videos/topvideo.mp4" type="video/mp4">
            </video>
            <div class="bottom-shadow"></div>
            <div class="font-head animate__animated">
                <p style="font-size: 4vw;font-family: 'hyyakuheiw';">Ignite Imagination <br/>Launch the <span style="color: #176dff;">FUTURE</span></p>
                <p style="font-size: 1.5vw;">Next-generation smart glasses, with outstanding image quality and precise manufacturing, set high standards for smart eyewear.</p>
                <div class="arrowSS">
                    <img src="../assets/images/mouse.png" class="to-mouse">
                </div>
            </div>
        </div>
        <div class="floor f-switch" :style="{backgroundImage:'url('+require('../assets/images/homes/floor2.png')+')'}">
            <div class="f2-to1">
                <img class="single-product" src="../assets/images/homes/product2-1.png">
                <div class="single-text">
                    <p class="single-text-p1">Future Glass <span style="color: #4963AD;">Crosser</span></p>
                    <p class="single-text-p2">TRAVEL LIGHT, FASHIONABLY <br/>AND WITNESS THE SPECTACULAR ON YOUR GLASSES</p>
                </div>
                <div class="btns btns-product">
                    <router-link to="/Appointment?productId=b384edd2-7707-11ee-b86f-00163e2ab162" class="c-btn c-btn-blue">Book now</router-link>
                    <router-link to="/Through" class="c-btn">Learn more</router-link>
                </div>
            </div>
            <div class="f2-to2" v-if="showThroughBack == false" :style="{backgroundImage:'url('+require('../assets/images/homes/product2-2.png')+')'}">
                </div>
        </div>
    </div>
</template>
<style scoped lang="less">

.floor {
    color: white;

    video {
        width: 100%;
        position: relative;
        z-index: 0;
    }

    .bottom-shadow {
        bottom: -1px;
    }

    .font-head {
        position: absolute;
        left: 0;
        bottom: 4rem;
        z-index: 10;
        width: 100%;
        height: 20rem;
    }

    // .f-nothing {
    //     .d-img {
    //         position: absolute;
    //         z-index: 0;
    //         width: 100%;
    //         height: 100%;
    //         background-position: 50%;
    //         background-repeat: no-repeat;
    //         background-size: cover;
    //     }
    // }

    .f2-to1{
        // border: 1px solid red;
    }

    .f2-to2{
        position: absolute;
        z-index: 0;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .single-product{
        // border: 1px solid red;
        width: 45vw;
        height: auto;
        margin-bottom: 3vh;
    }
    .single-text{
        color: #4C4C4C;
    }
    .single-text-p1{
        font-size: 2.3vw;
        font-weight: bold;
    }
    .single-text-p2{
        font-size: 3vw;
        font-weight: bold;
    }

    .btns {
        position: relative;
        z-index: 10;
        width: 100%;
        height: auto;
        padding-top: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 1rem;

        .c-btn{
            border: 1px solid white;
            color: white;
            text-decoration: none;
        }

        .c-btn-blue{
            border: none;
            background-color: #4963AD;
        }

        .c-btn:hover{
            background-color: white;
            color: black;
        }
    }
}

.f-switch{
    background-color: antiquewhite;
    display: flex;
    justify-content: center;
    align-items: center;
}


</style>
<script>

export default {
    data() {
        return {
            showProductType: "blue",
            showThroughBack: true
        };
    },
    mounted() {
        window.addEventListener("load", (e) => {
            $(".font-head").eq(0).addClass("animate__bounceInUp");
        });
        window.addEventListener("scroll", (e) => { this.ergodic(); });
    },
    methods: {
        ergodic: function () {
            if ($(".v-home").length < 1)
                return;
            let _self = this;
            var screenHeight = window.screen.height;
            let hVal = screenHeight / 3;
            $.each($(".v-home").children(".floor"), function (index, item) {
                if (index == 0)
                    return true;
                if (index == 1) {
                    if (item.getBoundingClientRect().top < (screenHeight / 3)) {
                        $(item).find(".font-absolute").addClass("animate__bounceInUp");
                    }
                    if (item.getBoundingClientRect().top < -50) {
                        _self.showThroughBack = false;
                    }
                }
                else {
                    if (item.getBoundingClientRect().top < hVal) {
                        $(item).find(".font-absolute").addClass("animate__bounceInUp");
                    }
                }
            });
        },
        showProduct: function (type) {
            this.showProductType = type;
        }
    }
}
</script>